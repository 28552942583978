<template>
  <div class="go">
    <div class="go__hero">
      <div class="container">
        <div class="go__hero-descr">
          <img
            src="@/assets/img/magnum-go/logo.svg"
            alt="image"
            width="124"
            height="104"
            class="go__hero-logo"
            data-aos-duration="1000"
            data-aos="fade-down"
          />
          <h2
            class="go__hero-title"
            data-aos-duration="1000"
            data-aos="fade-right"
          >
            {{ $t("homeDeliveryTitle") }}
          </h2>
          <div
            class="go__hero-text"
            data-aos-duration="1000"
            data-aos="fade-up"
          >
            {{ $t("homeDeliveryText") }}
          </div>
        </div>
      </div>
      <img
        src="@/assets/img/magnum-go/hero.png"
        alt="image"
        class="go__hero-img-desktop"
        data-aos-duration="1000"
        data-aos="zoom-in"
      />
      <img
        src="@/assets/img/magnum-go/hero-mobile.png"
        alt="image"
        class="go__hero-img-mobile"
        data-aos-duration="1000"
        data-aos="zoom-in"
      />
    </div>

    <div class="container">
      <!-- ADVANTAGES -->
      <div class="go__advantages">
        <h2
          class="go__advantages-title title"
          data-aos-duration="1000"
          data-aos="fade-down"
        >
          {{ $t("ourAdvantages") }}
        </h2>

        <div class="go__advantages-content">
          <img
            src="@/assets/img/magnum-go/advantages.jpg"
            alt="image"
            class="go__advantages-img"
            data-aos-duration="1000"
            data-aos="fade-right"
          />

          <div class="go__advantages-items">
            <div
              class="go__advantages-item"
              data-aos-duration="1000"
              data-aos="zoom-in"
            >
              <div class="go__advantages-item-title">
                {{ $t("savingTimeTitle") }}
              </div>
              <p class="go__advantages-descr">
                {{ $t("savingTimeText") }}
              </p>
            </div>
            <div
              class="go__advantages-item"
              data-aos-duration="1000"
              data-aos="zoom-in"
            >
              <div class="go__advantages-item-title">
                {{ $t("flexibleDeliveryTitle") }}
              </div>
              <p class="go__advantages-descr">
                {{ $t("flexibleDeliveryText") }}
              </p>
            </div>

            <div
              class="go__advantages-item"
              data-aos-duration="1000"
              data-aos="zoom-in"
            >
              <div class="go__advantages-item-title">
                {{ $t("freshnessProductsTitle") }}
              </div>
              <p class="go__advantages-descr">
                {{ $t("freshnessProductsText") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- go INFO -->
      <div class="go__info">
        <h2
          class="go__work-title"
          data-aos-duration="1000"
          data-aos="fade-down"
        >
          {{ $t("HowWorkingTitle") }}
        </h2>

        <div class="go__wrapper">
          <div class="go__info-descr">
            <div class="go__work">
              <h2
                class="go__work-subtitle"
                data-aos-duration="1000"
                data-aos="fade-right"
                v-html="$t('HowWorkingText')"
              ></h2>
              <div class="go__times">
                <div class="go__times-inner">
                  <ul class="go__times-clock">
                    <li class="go__times-item">
                      {{ $t("HowWorkingItemTime_1") }}
                    </li>
                    <li class="go__times-item">
                      {{ $t("HowWorkingItemTime_2") }}
                    </li>
                    <li class="go__times-item">
                      {{ $t("HowWorkingItemTime_3") }}
                    </li>
                    <li class="go__times-item">
                      {{ $t("HowWorkingItemTime_4") }}
                    </li>
                  </ul>
                  <div class="go__times-descr">
                    {{ $t("goTimesDescr") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="go__delivery">
              <h2
                class="go__delivery-title"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                {{ $t("howMuchTitle") }}
              </h2>
              <div class="go__delivery-list">
                <p
                  class="go__delivery-item"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  v-html="$t('howMuchItem')"
                ></p>
              </div>
            </div>
          </div>

          <div class="go__aside" data-aos="fade-left" data-aos-duration="1000">
            <img src="@/assets/img/magnum-go/aside.jpg" alt="image" />
          </div>
        </div>
      </div>

      <!-- APP -->
      <div class="go__app">
        <img
          src="@/assets/img/magnum-go/phone.png"
          alt="phone image"
          class="go__app-img"
          data-aos="fade-right"
          data-aos-duration="1000"
        />

        <h2 class="go__app-title" data-aos="fade-left" data-aos-duration="1000">
          {{ $t("mobileApplication") }}
          <span>{{ $t("mobileApplicationSpan") }}</span>
          {{ $t("mobileApplicationSecond") }}
        </h2>

        <div class="go__app-links-wrapper">
          <div class="go__app-btns">
            <a
              :href="appStoreLink"
              class="go__app-btn"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/app-store.png"
                alt="icon"
                width="154"
                height="44"
              />
            </a>
            <a
              :href="googlePlayLink"
              class="go__app-btn"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src="@/assets/img/club-page/google-play.png"
                alt="icon"
                width="154"
                height="44"
              />
            </a>
            <!-- <a
              :href="appGalleryLink"
              class="go__app-btn"
              target="_blank"
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              <img
                src="@/assets/img/magnum-club/app-gallery.png"
                alt="image"
                width="149"
                height="41"
              />
            </a> -->
          </div>
          <div v-if="magnumCoLink" class="go__app-links">
            <a
              :href="linkOffer"
              download
              class="go__app-link"
              data-aos="fade-right"
              data-aos-duration="1000"
              >{{ $t("publicOffer") }}</a
            >
            <a
              :href="linkFaqExpress"
              download
              class="go__app-link"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              {{ $t("FAQ") }}</a
            >
            <a
              download
              :href="linkFaqRoznica"
              class="go__app-link"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              {{ $t("FAQRetail") }}
            </a>
            <a
              download
              :href="linkFaqB2B"
              class="go__app-link"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              {{ $t("FAQB2B") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMagnumGoGooglePlay,
  getMagnumGoAppStore,
  getAppGallery,
  getMagnumGoLink,
} from "@/api/links";
export default {
  name: "MagnumGo",
  data() {
    return {
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      magnumCoLink: null,
    };
  },
  computed: {
    linkOffer() {
      return this.$helpers.getAbsolutePath(this.magnumCoLink.public_offer);
    },
    linkFaqRoznica() {
      return this.$helpers.getAbsolutePath(this.magnumCoLink.faq);
    },
    linkFaqExpress() {
      return this.$helpers.getAbsolutePath(this.magnumCoLink.faq_express);
    },
    linkFaqB2B() {
      return this.$helpers.getAbsolutePath(this.magnumCoLink.faq_b2b);
    },
  },
  created() {
    getMagnumGoGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getMagnumGoAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
    getMagnumGoLink().then((magnumCoLink) => {
      this.magnumCoLink = magnumCoLink.attributes;
    });
  },
};
</script>
